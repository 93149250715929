<template>
  <div class="bt-transaksi pa-5">
    <h6
      class="text-h5 font-weight-bold orange--text text--lighten-1 d-flex align-center"
    >
      <div
        class="rounded-pill orange lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3"
      >
        <v-icon small color="white">mdi-wallet-plus-outline</v-icon>
      </div>
      Perpanjangan Tabungan
    </h6>
    <v-row class="mt-5">
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Rembug</label>
        <v-select
          color="black"
          v-model="form.data.cm_code"
          :items="opt.rembug"
          @change="getAnggota()"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Anggota</label>
        <v-select
          color="black"
          v-model="form.data.cif_no"
          :items="opt.anggota"
          @change="setProfile()"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">No Anggota</label>
        <v-text-field
          color="black"
          :value="form.data.cif_no"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Nama</label>
        <v-text-field
          color="black"
          :value="profile.nama"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Produk</label>
        <v-select
          color="black"
          v-model="form.data.produk"
          :items="opt.produk"
          @change="setTabungan()"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Rencana Jangka Waktu</label>
        <v-text-field
          color="black"
          :value="form.data.perpanjangan.rencana_jangka_waktu"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Rencana Jangka Waktu Baru</label>
        <v-text-field
          color="black"
          v-model="form.data.perpanjangan.rencana_jangka_waktu_baru"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Tanggal Perpanjangan</label>
        <v-text-field
          color="black"
          :value="form.data.perpanjangan.tanggal_perpanjangan"
          type="date"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Counter Angsuran</label>
        <v-text-field
          color="black"
          :value="form.data.perpanjangan.counter_angsuran"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          disabled
        />
      </v-col>
    </v-row>
    <div class="d-flex justify-center mt-4 pt-4">
      <v-btn class="orange lighten-1 white--text mx-2" @click="$router.push(`/tabungan`)">Kembali</v-btn>
      <v-btn class="orange lighten-1 white--text mx-2" @click="doSave()"> Simpan </v-btn>
    </div>
    <v-snackbar v-model="alert.show" :timeout="3000">
      {{ alert.msg }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
import helper from "@/utils/helper"
export default {
  name: "KeangotaanKeluar",
  components: {
  },
  data() {
    return {
      profile: Object,
      form: {
        data: {
          cif_no: null,
          cm_code: null,
          fa_code: null,
          produk: null,
          perpanjangan: {
            account_saving_no: null,
            rencana_jangka_waktu: null,
            rencana_jangka_waktu_baru: 0,
            tanggal_perpanjangan: null,
            counter_angsuran: 0,
            created_by: null
          }
        }
      },
      opt: {
        produk: [],
        rembug: [],
        anggota: [],
      },
      alert: {
        show: false,
        msg: null,
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...helper,
    async getRembug() {
      let day = new Date().getDay();
      let payload = new FormData();
      payload.append("fa_code", this.user.fa_code);
      payload.append("day", day);
      // payload.append("day", 1);
      try {
        let req = await services.infoRembug(payload, this.user.token);
        if (req.status === 200) {
          let { data } = req.data;
          this.opt.rembug = [];
          if(data){
            data.map((item) => {
              let opt = {
                value: item.cm_code,
                text: item.cm_name,
                data: item,
              };
              this.opt.rembug.push(opt);
            });
          }
        } else {
          this.alert = {
            show: true,
            msg: 'Gagal mendapatkan data Rembug',
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getAnggota() {
      let payload = new FormData();
      payload.append("cm_code", this.form.data.cm_code);
      try {
        let req = await services.infoMember(payload, this.user.token);
        if (req.status === 200) {
          let { data } = req.data;
          this.opt.anggota = [];
          data.map((item) => {
            let opt = {
              value: item.cif_no,
              text: item.nama,
              data: item,
            };
            this.opt.anggota.push(opt);
          });
          this.resetTabungan()
        } else {
          this.alert = {
            show: true,
            msg: 'Gagal mendapatkan data Anggota',
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getTabungan() {
      let payload = new FormData();
      payload.append("cif_no", this.form.data.cif_no);
      try {
        let req = await services.tabunganAnggota(payload, this.user.token);
        if (req.status === 200) {
          let { data } = req.data;
          this.opt.produk = [];
          if(data[0].product_name){
            data.map((item) => {
              let opt = {
                value: item.account_saving_no,
                text: item.product_name,
                data: item,
              };
              this.opt.produk.push(opt);
            });
          }
        } else {
          this.alert = {
            show: true,
            msg: 'Gagal mendapatkan data Tabungan Anggota',
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    setProfile() {
      let profile = this.opt.anggota.find(
        (item) => item.value == this.form.data.cif_no
      ).data;
      this.profile = profile
      this.getTabungan()
    },
    setTabungan() {
      let tabungan = this.opt.produk.find(
        (item) => item.value == this.form.data.produk
      ).data;
      this.form.data.perpanjangan = {
        account_saving_no: tabungan.account_saving_no,
        rencana_jangka_waktu: tabungan.rencana_jangka_waktu,
        rencana_jangka_waktu_baru: 0,
        tanggal_perpanjangan: this.today(),
        counter_angsuran: tabungan.counter_angsuran,
        created_by: this.user.fa_code
      }
    },
    resetTabungan() {
      this.form.data.perpanjangan = {
        account_saving_no: null,
        rencana_jangka_waktu: null,
        rencana_jangka_waktu_baru: 0,
        tanggal_perpanjangan: this.today(),
        counter_angsuran: 0,
        created_by: null
      }
    },
    async doSave() {
      if(this.form.data.perpanjangan.account_saving_no && this.form.data.cif_no){
        let rencana_jangka_waktu = Number(this.form.data.perpanjangan.rencana_jangka_waktu)
        let rencana_jangka_waktu_baru = Number(this.form.data.perpanjangan.rencana_jangka_waktu_baru)
        if(rencana_jangka_waktu < rencana_jangka_waktu_baru) {
          let payload = new FormData();
          let payloadData = this.form.data.perpanjangan;
          payloadData.tanggal_perpanjangan = this.formatDate(payloadData.tanggal_perpanjangan)
          for (let key in payloadData) {
            payload.append(key, payloadData[key]);
          }
          let req = await services.perpanjanganTabungan(
            payload,
            this.user.token
          );
          let { data, status, msg } = req.data
          if(req.status == 200 && status){
            this.alert = {
              show: true,
              msg: msg,
            };
            setTimeout(() => {
              this.$router.push(`/tabungan`);
            }, 1000);
          } else {
            this.alert = {
              show: true,
              msg: msg,
            };
          }
        } else {
          this.alert = {
            show: true,
            msg: "Rencana Jangka Waktu Baru tidak valid",
          };
        }
      } else {
        this.alert = {
          show: true,
          msg: "Silahkan pilih anggota dan produk tabungan",
        };
      }
    },
    generateDate(){
      var dateObj = new Date();
      var month = String(dateObj.getUTCMonth() + 1).padStart(2, '0')
      var day = String(dateObj.getUTCDate()).padStart(2, '0')
      var year = dateObj.getUTCFullYear();
      var hours = dateObj.getHours()
      var minutes = dateObj.getMinutes()
      var seconds = dateObj.getSeconds()
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    notif(title, msg, type) {
      this.alert = {
        show: true,
        msg: msg,
      };
    },
  },
  mounted() {
    this.getRembug();
    this.form.data.perpanjangan.tanggal_perpanjangan = this.today()
  },
};
</script>
